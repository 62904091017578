/* eslint-disable max-len */
import * as React from 'react'
import styles from './phone.module.css'

const Phone: React.FC = ({ children }) => (
  <figure className={styles.figure}>
    <svg width="100%" height="100%" viewBox="0 0 360 722" version="1.1" className={styles.svg}>
      <g id="iPhone-X">
        <path d="M54.74,0.752C25.5,0.752 1.961,24.291 1.961,53.531L1.961,668.209C1.961,697.449 25.5,720.99 54.74,720.99L161.703,720.99L163.889,717.893L196.109,717.893L198.297,720.99L305.26,720.99C334.5,720.99 358.039,697.449 358.039,668.209L358.039,53.531C358.039,24.291 334.5,0.752 305.26,0.752L54.74,0.752ZM55.779,22.201C38.011,22.201 23.707,36.505 23.707,54.273L23.707,667.469C23.707,685.237 38.011,699.541 55.779,699.541L304.221,699.541C321.989,699.541 336.293,685.237 336.293,667.469L336.293,54.273C336.293,36.505 321.989,22.201 304.221,22.201L272.848,22.201C269.909,22.201 267.49,24.62 267.49,27.559L267.49,30.025C267.49,39.679 259.72,47.451 250.066,47.451L109.934,47.451C100.28,47.451 92.51,39.679 92.51,30.025L92.51,27.559C92.51,24.62 90.091,22.201 87.152,22.201L55.779,22.201Z" />
        <g transform="matrix(1,0,0,1,-923.494,0)">
          <g id="g5128">
            <g id="g5116">
              <rect id="path5106" x="926.657" y="70.01" width="4.166" height="4.924" className={styles.plastic} />
              <rect id="path5104" x="926.657" y="74.934" width="4.166" height="572.125" className={styles.metal} />
              <rect id="path5102" x="926.657" y="647.059" width="4.166" height="4.926" className={styles.plastic} />
              <path
                id="path5100"
                d="M1280.33,651.984L1276.17,651.984L1276.17,668.461C1276.17,695.043 1254.77,716.443 1228.18,716.443L978.805,716.443C952.223,716.443 930.823,695.043 930.823,668.461L930.823,651.984L926.657,651.984L926.657,666.281C926.657,695.96 950.55,719.853 980.229,719.853L1226.76,719.853C1256.44,719.853 1280.33,695.96 1280.33,666.281L1280.33,651.984Z"
                className={styles.metal}
              />
              <rect id="path5098" x="1276.17" y="647.059" width="4.166" height="4.926" className={styles.plastic} />
              <rect id="path5096" x="1276.17" y="74.934" width="4.166" height="572.125" className={styles.metal} />
              <rect id="path5094" x="1276.17" y="70.01" width="4.166" height="4.924" className={styles.plastic} />
              <path
                id="rect4873"
                d="M980.229,2.266C950.55,2.266 926.657,26.159 926.657,55.838L926.657,70.01L930.823,70.01L930.823,54.225C930.823,27.642 952.223,6.244 978.805,6.244L1228.18,6.244C1254.77,6.244 1276.17,27.642 1276.17,54.225L1276.17,70.01L1280.33,70.01L1280.33,55.838C1280.33,26.159 1256.44,2.266 1226.76,2.266L980.229,2.266Z"
                className={styles.metal}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div className={styles.content}>{children}</div>
  </figure>
)

export default Phone
